<template>
  <a-drawer
    title="Basic Drawer"
    placement="right"
    :visible="visible"
    @close="onClose"
    width="100%"
  >
    <slot>Drawer</slot>
  </a-drawer>
</template>

<script>
  export default {
    data() {
      return {
        visible: false
      }
    },
    methods: {
      open() {
        this.visible = true
      },
      onClose() {
        console.log('onClose')
      }
    }
  }
</script>

<style lang="less" scoped>

</style>
